export default {
  threeColumnsHome: {
    gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
    gridGap: ['0', '16px'],
    width: '100%',
  },
  twoColumnsHome: {
    gridTemplateColumns: ['1fr', '1fr 2fr'],
    gridGap: '0',
    width: '100%',
    height: ['auto', 'auto'],
  },
  twoColumnsPages: {
    gridTemplateColumns: ['1fr', '500px 3fr'],
    gridGap: '0px',
    maxWidth: ['auto', '1200px'],
  },
  twoColumnsDiscover: {
    gridTemplateColumns: ['1fr', '1fr 2fr'],
    gridGap: '15px',
    width: '100%',
    maxWidth: '1180px',
    mb: '20px',
    '@media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) and (stroke-color:transparent)': {
      mb: '90px',
    },
  },
  twoColumnsDiscoverItems: {
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: '0px',
    width: '100%',
  },
  twoColumnsThanks: {
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: '15px',
    width: '100%',
    mb: ['0', '20px'],
  },
  twoColumnsThanksItems: {
    gridTemplateColumns: 'auto auto',
    gridGap: '0px',
    width: '100%',
  },
  partnersGrid: {
    gridTemplateColumns: [
      'repeat(4, minmax(min(100%,30px), 1fr))',
      'repeat(auto-fill, minmax(min(100%,80px), 1fr))',
    ],
    width: ['100%', '800px'],
    gridGap: ['16px', '14px'],
    px: ['60px', 0],
  },
  partnersGridHome: {
    gridTemplateColumns: [
      'repeat(4, minmax(min(100%,30px), 1fr))',
      'repeat(auto-fill, minmax(min(100%,80px), 1fr))',
    ],
    width: ['100%', '800px'],
    gridGap: ['16px', '14px'],
    px: 0,
  },
  videoComponent: {
    gridTemplateColumns: ['1fr', '1.5fr 1fr'],
    maxWidth: ['auto', '1100px'],
  },
  mainVideoDescription: {
    gridTemplateColumns: ['auto auto', '1fr 2fr'],
    gridGap: '0',
  },
  mainVideoDescriptionModal: {
    gridTemplateColumns: ['1fr', '1fr 2fr'],
    gridGap: '0',
  },
  videoListContainer: {
    overflowY: 'scroll',
    height: ['488px', '482px'],
    scrollSnapType: 'y mandatory',
    px: ['5px', '0'],
  },
  videoList: {
    gridTemplateColumns: ['3fr auto 2fr', '2.5fr auto 2fr'],
    gridGap: '0',
    cursor: 'pointer',
    scrollSnapAlign: 'start',
  },
  carouselContainer: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    width: ['300px', '1200px'],
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollSnapType: 'x mandatory',
  },
}

export default {
  root: {
    fontFamily: 'medium',
    fontSize: '18px',
    color: 'primary',
    a: {
      textDecoration: 'none',
      color: 'primary',
    },
    '@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)': {
      imageRendering: '-webkit-optimize-contrast',
    },
    ul: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
  },
}

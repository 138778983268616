export default {
  borderRadius: '0px',
  border: '1px solid primary',
  backgroundColor: '#f2f2f2',
  outline: 'none',
  fontSize: '14px',
  py: '13px',
  '::placeholder': {
    color: 'primary',
    fontFamily: 'light',
    fontSize: '14px',
  },
  // ':focus': {
  //   border: '1px solid #97D9F6',
  // },
}

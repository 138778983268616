import axios from 'axios'
import getConfig from 'next/config'

import Logger from './logger'

const { publicRuntimeConfig } = getConfig()

const APIConfig = publicRuntimeConfig.APIConfig

const API = (apiName = '') => (domain) => {
  const findConfigByName = (config) => config.name === apiName

  const service = APIConfig.find(findConfigByName)

  if (!service) {
    throw new Error(`This ${apiName} does not exist on the API configuration`)
  }

  const domainEndpoint = {
    UI: '/api',
    default: service.baseUrl + service.endpoint,
  }

  const endpoint = domainEndpoint[domain] || domainEndpoint['default']

  const get = (path, data = {}) => {
    Logger.info(`${path}`)

    const headers = {
      ...data.headers,
    }

    const params = {
      ...data.query,
    }

    const options = {
      headers,
      params,
    }

    return axios.get(endpoint + path, options).catch((error) => {
      Logger.error(`Unable to GET: ${path}`)
      Logger.error(`Error: ${JSON.stringify(error)}`)
      throw error
    })
  }

  const post = (path, params) => {
    const { headers = {}, body } = params
    Logger.info(`${path}, body: ${body}, method: 'POST`)

    const options = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    return axios.post(endpoint + path, body, options).catch((error) => {
      Logger.error(
        `Unable to POST: ${path}, Body: ${JSON.stringify(body)} Error: ${
          error.message
        }`,
      )
      throw error
    })
  }

  return {
    get,
    post,
  }
}
export default API

export default {
  noPitchDataLink: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'quirky',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'primary',
    backgroundColor: 'secondary',
    height: '30px',
    width: 'max-content',
    borderRadius: '50px',
    minWidth: '150px',
    textTransform: 'uppercase',
    transition: '0.2s',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        backgroundColor: 'primary',
        color: 'secondary',
      },
    },
  },
}

import React from 'react'
import { Global, css } from '@emotion/core'

import MontserratBold from '../assets/fonts/Montserrat-Bold.ttf'
import MontserratLight from '../assets/fonts/Montserrat-Light.ttf'
import MontserratMedium from '../assets/fonts/Montserrat-Medium.ttf'
import MontserratBoldWoff from '../assets/fonts/Montserrat-Bold.woff'
import MontserratLightWoff from '../assets/fonts/Montserrat-Light.woff'
import MontserratMediumWoff from '../assets/fonts/Montserrat-Medium.woff'
import QuirkySpringRegularTtf from '../assets/fonts/QuirkySpring-Regular.ttf'
import QuirkySpringRegularWoff from '../assets/fonts/QuirkySpring-Regular.woff'

const styles = css`
  @font-face {
    font-family: 'Montserrat-Bold';
    src: url(${MontserratBoldWoff}) format('woff'),
      url(${MontserratBold}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat-Medium';
    src: url(${MontserratMediumWoff}) format('woff'),
      url(${MontserratMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat-Light';
    src: url(${MontserratLightWoff}) format('woff'),
      url(${MontserratLight}) format('truetype');
  }
  @font-face {
    font-family: 'QuirkySpring-Regular';
    src: url(${QuirkySpringRegularWoff}) format('woff'),
      url(${QuirkySpringRegularTtf}) format('truetype');
  }
  html {
    scroll-behavior: smooth;
  }
`

const GlobalComponent = () => <Global styles={styles} />

export default GlobalComponent

export default {
  container: {
    default: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
      gridTemplateColumns: ['1fr', '300px 60px 400px'],
      mb: ['50px', '100px'],
      px: ['20px', '0'],
    },
    manchester: {
      display: 'grid',
      rowGap: '15px',
      columnGap: '20px',
      gridTemplateColumns: ['1fr', '300px 60px 400px'],
      mb: ['50px', '100px'],
      px: ['20px', '0'],
    },
  },
  inputContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
    label: {
      mb: '10px',
    },
  },
  countryContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
  },
  selectContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
    fontSize: ['15px', '18px'],
    label: {
      mb: '10px',
    },
  },
  phoneContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
  },
  checkboxContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
    fontSize: '12px',
    alignSelf: 'center',
    label: {
      div: {
        p: {
          position: 'relative',
          top: '5px',
        },
      },
    },
  },
  markdownContainer: {
    formTitle: {
      gridArea: ['inherit', '1/1/1/4'],
      fontSize: ['30px', '40px'],
      textAlign: 'center',
      fontFamily: 'quirky',
      mb: '20px',
      p: { m: '0' },
    },
    firstParagraphDefault: {
      gridArea: ['inherit', '2/1/6/2'],
      textAlign: ['center', 'left'],
      fontSize: ['15px', '20px'],
      lineHeight: ['25px', '40px'],
      p: { m: '0' },
    },
    secondParagraphDefault: {
      gridArea: ['inherit', '5/1/9/2'],
      textAlign: ['center', 'left'],
      fontSize: ['15px', '20px'],
      lineHeight: ['25px', '40px'],
      p: { m: '0' },
    },
    termsTextDefault: {
      gridArea: ['inherit', '9/1/9/2'],
      p: { m: '0' },
      fontSize: '12px',
      textAlign: ['center', 'left'],
    },
    firstParagraphManchester: {
      gridArea: ['inherit', '2/1/6/2'],
      fontSize: ['15px', '20px'],
      lineHeight: ['25px', '40px'],
      textAlign: ['center', 'left'],
      p: { m: '0' },
    },
    termsTextManchester: {
      gridArea: ['inherit', '6/1/6/2'],
      p: { m: '0' },
      fontSize: '12px',
      textAlign: ['center', 'left'],
    },
    optInText: {
      fontSize: '12px',
      textAlign: 'center',
      gridColumnStart: ['1', '3'],
      gridColumnEnd: ['2', '4'],
    },
  },
  dateContainer: {
    gridColumnStart: ['1', '3'],
    gridColumnEnd: ['2', '4'],
    div: {
      width: '100%',
      div: {
        m: '1px 0',
        input: {
          p: '8px',
          fontSize: '14px',
          width: '100%',
          boxSizing: 'border-box',
          backgroundColor: 'background',
          color: 'primary',
          border: '1px solid #ced4da',
          borderRadius: '0px',
          mt: '4px',
          outline: 'none',
          '::placeholder': {
            color: 'primary',
            fontFamily: 'light',
          },
          ':focus': {
            border: '1px solid #97D9F6',
          },
        },
      },
    },
  },
  multipleCheckboxes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    checksContainer: {
      label: {
        alignItems: 'center',
        div: {
          p: {
            my: '10px',
          },
          svg: {
            ':nth-of-type(0)': {
              color: 'secondary',
            },
            ':nth-of-type(1)': {
              color: 'secondary',
            },
          },
        },
      },
    },
  },
  checkbox: {
    div: {
      label: {
        div: {
          display: 'flex',
          alignItems: 'center',
          p: {
            fontFamily: 'light',
          },
          svg: {
            ':nth-of-type(0)': {
              color: 'secondary',
            },
            ':nth-of-type(1)': {
              color: 'secondary',
            },
          },
        },
      },
    },
  },
}

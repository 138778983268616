import React from 'react'
import App from 'next/app'
import Head from 'next/head'

import { ThemeProvider, merge } from 'theme-ui'
import { GlobalComponent, theme } from '../styles'

import { FormTheme } from '@guidesmiths/react-form-builder'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-number-input/style.css'

import initServiceWorker from '../mocks'
import Utils from '../utils'
import { AppProviders } from '../contexts'
import router from 'next/router'
import layout from '../mocks/fixtures/layout/get.json'
import forms from '../mocks/fixtures/forms/get.json'

Utils.sentry.init()
initServiceWorker()

export default GreenroomApp

function GreenroomApp({ Component, ...props }) {
  const { layout } = props

  return (
    <>
      <Head>
        <title>{layout?.meta?.title}</title>
        <link rel="shortcut icon" href={layout?.meta?.favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <AppProviders>
        <ThemeProvider theme={merge(FormTheme, theme)}>
          <GlobalComponent />
          <Component {...props} />
        </ThemeProvider>
      </AppProviders>
    </>
  )
}
GreenroomApp.getInitialProps = getGreenroomAppInitialProps

const redirectToClosed = async (ctx) => {
  if (ctx.req) {
    ctx.res.writeHead(302, { Location: '/closed' })
    ctx.res.end()
  } else {
    await router.push('/closed')
  }
}

async function getGreenroomAppInitialProps(appContext) {
  const appProps = await App.getInitialProps(appContext)
  // const domain = process.browser ? 'UI' : ''

  if (appContext.ctx.asPath.includes('closed')) {
    // if (!appContext.ctx.asPath.includes('closed')) {
    await redirectToClosed(appContext.ctx)
  }

  const query = {
    locale: Utils.parseUrl(appContext.ctx.asPath).locale,
  }
  const locale = Utils.locale.getLocaleFromQuery(query)

  const utmParams = Utils.getUTMParamsFromUrl(appContext.ctx.asPath)

  const isMobile = Utils.isMobile(appContext)

  return {
    isMobile,
    locale,
    utmParams,
    layout,
    forms,
    enabled: false,
    ...appProps,
  }
}

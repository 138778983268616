import * as Sentry from '@sentry/node'

import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'
import isBrowser from './isBrowser'

const config = getConfig()
const distDir = `${config.serverRuntimeConfig.rootDir}/.next`

const configSentryReqScope = (req) => {
  if (!req) return
  Sentry.configureScope((scope) => {
    scope.setTag('host', req.headers.host)
    scope.setTag('url', req.url)
    scope.setTag('method', req.method)
    scope.setContext('query', req.query)
    scope.setContext('body', req.body)
    scope.setContext('headers', req.headers)
  })
}

const init = () => {
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) return null

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
  })

  Sentry.configureScope((scope) => {
    scope.setTag('nodejs', process.version)
    scope.setTag('runtimeEngine', isBrowser() ? 'browser' : 'server')
  })
}

export default { configSentryReqScope, init }

export default {
  navbar: {
    fontFamily: 'quirky',
    color: 'tertiary',
    cursor: 'pointer',
    ':hover': {
      color: 'secondary',
    },
  },
  navbarMobile: {
    fontFamily: 'quirky',
    color: 'tertiary',
    cursor: 'pointer',
    my: '20px',
    fontSize: '24px',
  },
  partnerX: {
    ml: ['20px', '12px'],
    fontSize: '35px',
    color: 'tertiary',
  },
  homeHeader: {
    fontFamily: ['light', 'medium'],
    fontSize: '20px',
    my: ['15px', '0'],
    lineHeight: ['30px', '40px'],
    textAlign: 'center',
    maxWidth: '600px',
  },
  homeLinksTitle: {
    fontSize: ['30px', '40px'],
    fontFamily: 'quirky',
    lineHeight: ['35px', '20px'],
    textAlign: 'center',
    mb: '50px',
  },
  homeLinks: {
    position: 'relative',
    bottom: '5px',
    fontSize: '20px',
    fontFamily: 'quirky',
    backgroundColor: 'secondary',
    textAlign: 'center',
    mb: '30px',
    py: '10px',
    cursor: 'pointer',
    transition: 'linear 0.2s',
    ':hover': {
      color: 'tertiary',
      backgroundColor: 'primary',
    },
  },
  orangeTitle: {
    fontSize: ['30px', '40px'],
    textAlign: ['center', 'left'],
    fontFamily: 'quirky',
    mb: '30px',
  },
  homeWhiteText: {
    color: 'tertiary',
    fontFamily: ['light', 'medium'],
    fontSize: ['18px', '20px'],
    textAlign: ['center', 'left'],
    lineHeight: '25px',
    mb: '20px',
  },
  homeHeaderTermsTitle: {
    fontFamily: 'quirky',
    m: '30px 0 20px',
    cursor: 'pointer',
  },
  homeHeaderTerms: {
    fontFamily: 'light',
    fontSize: ['10px', '14px'],
    textAlign: 'center',
    maxWidth: '1200px',
    mb: '20px',
  },
  modalTitle: {
    fontFamily: 'quirky',
    fontSize: '40px',
    width: ['100%', '700px'],
  },
  modalText: {
    lineHeight: '25px',
    mt: '15px',
    width: ['100%', '700px'],
  },
  addressError: {
    lineHeight: '25px',
    width: ['100%', '700px'],
  },
  termsText: {
    fontSize: ['14px', '18px'],
    lineHeight: '25px',
    mt: '15px',
    width: ['auto', '1100px'],
    a: {
      color: 'secondary',
      width: ['auto', '800px'],
    },
    ml: ['15px', '0px'],
    mr: ['15px', '0px'],
    fontFamily: 'medium',
  },
  modalButtons: {
    display: 'flex',
  },
  pagesHeader: {
    fontSize: ['16px', '20px'],
    lineHeight: ['30px', '40px'],
    textAlign: ['center', 'left'],
  },
  pagesTitle: {
    fontSize: ['30px', '60px'],
    fontFamily: 'quirky',
    textAlign: 'center',
    maxWidth: '1180px',
    my: '20px',
  },
  discoverTitle: {
    fontSize: ['30px', '40px'],
    fontFamily: 'quirky',
    textAlign: 'center',
    maxWidth: '1180px',
    my: '20px',
  },
  challengesTitle: {
    fontSize: ['30px', '40px'],
    fontFamily: 'quirky',
    textAlign: 'center',
    maxWidth: '1180px',
    mt: '40px',
    mb: '70px',
  },
  aboutTitle: {
    fontSize: ['30px', '60px'],
    fontFamily: 'quirky',
    textAlign: 'center',
    maxWidth: '1180px',
    px: ['20px', '0'],
    my: '20px',
  },
  aboutDescription: {
    lineHeight: '25px',
    width: ['auto', '900px'],
    my: '20px',
    margin: '100px',
    px: ['20px', '0'],
  },
  aboutThumbnail: {
    fontSize: ['25px', '40px'],
    fontFamily: 'quirky',
    color: 'tertiary',
  },
  prizeTermsTitle: {
    fontFamily: 'quirky',
    m: '30px auto 20px',
    textAlign: 'center',
  },
  prizeTerms: {
    fontFamily: 'light',
    fontSize: '12px',
    mb: '20px',
    textAlign: ['center', 'left'],
  },
  videoDescription: {
    title: {
      fontFamily: 'bold',
      fontSize: '14px',
      color: 'tertiary',
      mb: ['5px', '0'],
    },
    description: {
      fontSize: '14px',
      color: 'tertiary',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    modal: {
      fontSize: '14px',
      color: 'tertiary',
    },
  },
  heroTitle: {
    fontSize: ['25px', '40px'],
    fontFamily: 'quirky',
    mb: '1rem',
    color: 'tertiary',
  },
  heroSubtitle: {
    fontFamily: 'light',
    color: 'tertiary',
    fontSize: ['18px', '20px', '25px'],
  },

  infoCardDescription: {
    fontFamily: 'light',
    color: 'tertiary',
    fontSize: '16px',
  },
  homeBasicText: {
    color: 'tertiary',
    fontSize: '16px',
    fontFamily: 'light',
  },
  homeYellowText: {
    color: 'secondary',
    fontSize: '16px',
    fontFamily: 'bold',
    marginLeft: '8px',
  },
  termsTitle: {
    fontFamily: 'QuirkySpring-Regular',
    color: 'tertiary',
    ml: ['70px', '86px'],
  },
  videoCardTitle: {
    width: '100%',
    color: 'tertiary',
    fontSize: ['20px', '30px'],
  },
  videoCardDescription: {
    color: 'tertiary',
    fontFamily: 'light',
    fontSize: ['14px', '16px'],
  },
  contenthubSectionTitle: {
    fontFamily: 'quirky',
    fontSize: '32px',
    color: 'tertiary',
    mb: '1.5rem',
  },
  noPitchFinderDataTitle: {
    textAlign: 'center',
    fontFamily: 'Montserrat-light',
    color: 'grey',
    mt: '1rem',
  },
}

export default {
  control: {
    color: '#1B254A',
    fontFamily: 'Montserrat-Light',
    backgroundColor: '#f2f2f2',
    backgroundClip: 'padding-box',
    border: '1px solid #1B254A',
    borderRadius: '0',
    outline: 'none',
    padding: '4px 0',
    fontSize: '14px',
    ':hover': {
      border: '1px solid #1B254A',
    },
    isFocused: {
      ':hover': {
        border: '1px solid #1B254A',
      },
      boxShadow: 'none',
      outline: 'none',
      border: '1px solid #1B254A',
      borderRadius: '0',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Montserrat-Light',
      padding: '4px 0',
      fontSize: '14px',
    },
    isSelected: {
      border: 'none',
    },
  },
  singleValue: { color: '#1B254A' },
  menu: { color: '#1B254A' },
  option: {
    isSelected: {
      backgroundColor: '#94d9f9',
      color: '#1B254A',
    },
    isFocused: {
      ':hover': { backgroundColor: '#d4f0fd' },
    },
  },
}

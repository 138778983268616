import { rest } from 'msw'

const sampleGETRes = require('../cypress/fixtures/sample/get.json')
const geoIPGETRes = require('../cypress/fixtures/geoip/get.json')
const layoutGETRes = require('../cypress/fixtures/layout/get.json')
const formsGETRes = require('../cypress/fixtures/forms/get.json')
const countGETRes = require('../cypress/fixtures/count/get.json')
const contactGETRes = require('../cypress/fixtures/contact/post.json')

export const handlers = [
  rest.get('http://kp-snacks.green.room/api/v1/sample', (req, res, ctx) => {
    return res(ctx.json(sampleGETRes))
  }),
  rest.get('/api/sample', (req, res, ctx) => {
    return res(ctx.json(sampleGETRes))
  }),

  rest.get('/api/geoip', (req, res, ctx) => {
    return res(ctx.json(geoIPGETRes))
  }),
  rest.get('http://kp-snacks.green.room/api/v1/geoip', (req, res, ctx) => {
    return res(ctx.json(geoIPGETRes))
  }),

  rest.get('/api/layout', (req, res, ctx) => {
    return res(ctx.json(layoutGETRes))
  }),
  rest.get('http://kp-snacks.green.room/api/v1/layout', (req, res, ctx) => {
    return res(ctx.json(layoutGETRes))
  }),

  rest.get('/api/forms', (req, res, ctx) => {
    return res(ctx.json(formsGETRes))
  }),
  rest.get('http://kp-snacks.green.room/api/v1/forms', (req, res, ctx) => {
    return res(ctx.json(formsGETRes))
  }),

  rest.get('/api/count', (req, res, ctx) => {
    return res(ctx.json(countGETRes))
  }),
  rest.get('http://kp-snacks.green.room/api/v1/count', (req, res, ctx) => {
    return res(ctx.json(countGETRes))
  }),

  rest.post('/api/prizeDraw', (req, res, ctx) => {
    return res(ctx.json(contactGETRes))
  }),
  rest.post('http://kp-snacks.green.room/api/v1/prizeDraw', (req, res, ctx) => {
    return res(ctx.json(contactGETRes))
  }),

  rest.post('/api/thousandCrickets', (req, res, ctx) => {
    return res(ctx.json(contactGETRes))
  }),
  rest.post(
    'http://kp-snacks.green.room/api/v1/thousandCrickets',
    (req, res, ctx) => {
      return res(ctx.json(contactGETRes))
    },
  ),
]

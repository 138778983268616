export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    px: ['1rem', '0'],
    mt: ['1rem', '60px'],
    mb: '20px',
  },
  formContainer: {
    px: ['20px', '0'],
    pb: ['30px', '0'],
    mt: ['15px', '30px'],
    width: '100vw',
    maxWidth: '580px',
  },
  partnersContainer: {
    display: 'flex',
    alignItems: 'center',
    my: '25px',
    flexDirection: 'column',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    px: '100px',
  },
  homeHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    px: ['20px', '100px'],
  },
  homeHeaderContainerMan: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    px: ['20px', '100px'],
    mb: '50px',
  },
  homeDivider: {
    width: ['100vw', '100%'],
    color: '#1B254A',
    border: '2px solid',
    px: ['0', '100px'],
  },
  homeDefaultDivider: {
    position: 'relative',
    bottom: '5px',
    width: ['100vw', '100%'],
    color: '#1B254A',
    border: '2px solid',
    px: ['0', '100px'],
    mb: ['40px', '0'],
  },
  headerDivider: {
    position: 'relative',
    bottom: '5px',
    m: ['30px 0', '0'],
    width: ['100vw', '100%'],
    color: '#1B254A',
    border: '2px solid',
  },
  homeSketchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pagesScrollHelper: {
    position: 'relative',
    top: ['-120px', '-150px'],
  },
  homeChevron: {
    height: '50px',
    width: '50px',
    position: 'relative',
    bottom: '150px',
    backgroundColor: 'secondary',
    border: '1px solid #fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    px: ['20px', '100px'],
    mb: '70px',
    maxWidth: '1500px',
  },
  homeLinksChallenges: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', 'stretch'],
    gridRow: ['2', '1'],
    height: 'auto',
    m: '0',
    p: ['30px 20px', '30px'],
    backgroundColor: 'secondary',
  },
  orangeContainerDiscover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', 'stretch'],
    height: '100%',
    m: '0',
    p: ['30px 20px', '30px'],
    backgroundColor: 'secondary',
  },
  videoContainer: {
    height: '600px',
  },
  navbarItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: ['20px', '40px', '60px'],
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    p: ['20px 15px', '40px 20px'],
    width: ['100%', '900px'],
  },
  batLimit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    p: '40px 20px',
    width: ['auto', '900px'],
    mb: ['50px', '100px'],
  },
  aboutDescriptionContainer: {
    mx: '20px',
    maxWidth: '1180px',
    textAlign: 'center',
    ul: {
      listStyleType: 'none',
    },
  },
  pagesHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', 'start'],
    width: ['auto', '400px'],
    justifySelf: 'center',
    p: ['20 0px', '20px 60px'],
  },
  thanksHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', 'start'],
    width: ['auto', '400px'],
    justifySelf: 'center',
    p: ['20 0px', '20px'],
  },
  prizeManHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['center', 'start'],
    width: ['auto', '400px'],
    justifySelf: 'center',
    p: ['20 0px', '0'],
  },
  thanksMobile: {
    height: '80px',
  },
  discoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    px: ['20px', '100px'],
    mt: ['20px', '10px'],
    mb: '60px',
  },
  promoContainer: {
    position: 'relative',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '850px',
    flexShrink: '0',
  },
  youtubeVideoContainer: {
    my: '40px',
  },
  youtubeVideoThumbnail: {
    my: '40px',
    height: ['160px', '450px'],
    width: ['300px', '800px'],
    backgroundColor: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  homeVideoThumbnail: {
    height: ['160px', 'auto'],
    width: ['auto', 'auto'],
    backgroundColor: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  challenguesVideoThumbnail: {
    height: ['160px', '450px'],
    width: ['300px', '800px'],
    backgroundColor: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  challenguesVideoThumbnailModal: {
    height: ['160px', '450px'],
    width: ['auto', '800px'],
    backgroundColor: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialContainer: {
    position: 'relative',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  aboutButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    gap: '20px',
    maxWidth: '1254px',
    pt: '28px',
  },
  aboutButtonsMobile: {
    gap: '20px',
    pt: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  challengesVideoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    bottom: ['35px', '54px'],
    width: '100%',
    pt: ['20px', '70px'],
    pb: ['50px', '120px'],
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  mainVideo: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    px: ['5px', '0'],
  },
  modalVideoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: ['auto', '800px'],
  },
  mainVideoItems: {
    display: 'flex',
  },
  mainVideoText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    p: ['0 20px', '20px'],
    mb: ['5px', '0'],
    backgroundColor: 'primary',
  },
  mainVideoTextModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    p: ['10px 20px 20px', '20px'],
    mb: ['5px', '0'],
    backgroundColor: 'primary',
  },
  videoListText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    p: '20px',
    backgroundColor: 'primary',
  },
  challengesCarouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    mb: ['60px', '120px'],
    px: ['10px', '0'],
  },
  carouselVideoContainer: {
    width: ['300px', '800px'],
    scrollSnapAlign: 'center',
  },
  termsContainer: {
    color: 'tertiary',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    width: ['380px', '1100px'],
    backgroundColor: 'primary',
    m: ['20px 5px 30px', '30px 0 50px'],
    px: ['5px', '0'],
  },
  termsContainerHeader: {
    color: 'tertiary',
    alignItems: 'left',
    width: ['380px', '1100px'],
    backgroundColor: 'primary',
    m: ['20px 5px 30px', '30px 0 50px'],
    px: ['5px', '0'],
  },
  contentHubSectionContainer: {
    mt: '1rem',
    mb: '6rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    justifyContent: 'center',
    textAlign: 'center',
  },
  videoCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ['90vw', '466px'],
    flex: ['1 0 80vw', '0 0 466px'],
    fontFamily: 'quirky',
    pb: '1rem',
    borderRadius: '20px',
    backgroundColor: 'primary',
    transform: 'rotateX(180deg)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  videoCardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '1rem',
    textAlign: 'center',
    gap: '1rem',
  },
  videoCardPlayContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: ['1rem', '4rem'],
    width: '100%',
    transform: ['scale(0.4)', 'scale(0.6)'],
    cursor: 'pointer',
  },
  pitchFinderDesktopContainer: {
    marginTop: '-30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '36px',
  },
  pitchFinderMobileContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  pitchFinderSearchBarWrapperDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
  },
  pitchFinderSearchBarWrapperMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    width: '90vw',
    margin: '0 auto',
  },
  pitchFinderListWrapperMobile: {
    marginTop: '26px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '570px',
    width: '90vw',
    mx: 'auto',
    overflowY: 'scroll',
    backgroundColor: 'tertiary',
    borderRadius: '10px',
    boxSizing: 'border-box',
    padding: '26px 15px 36px',
    color: 'primary',
  },
  pitchFinderListWrapper: {
    marginTop: '26px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '400px',
    width: '740px',
    fontFamily: 'Montserrat-medium',
    overflowY: 'scroll',
    backgroundColor: 'tertiary',
    borderRadius: '10px',
    boxSizing: 'border-box',
    padding: '26px 50px 36px',
    color: 'primary',

    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
      boxSizing: 'border-box',
      borderRadius: '15px',
      background: (theme) => theme.colors.tertiary,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      background: (theme) => theme.colors.secondary,
    },
    '&::-webkit-scrollbar-track': {
      background: (theme) => theme.colors.tertiary,
      borderRadius: '15px',
    },
  },
  pitchesListItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '30px 10px',
    borderBottom: '1px solid #1B254A',
  },
  pitchesListItemContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    padding: '20px 10px',
    borderBottom: '1px solid #1B254A',
  },
  pitchesListItem: {
    fontFamily: 'Montserrat-light',
  },
  pitchesListItemBold: {
    fontFamily: 'Montserrat-Bold',
  },
  pitchesListItemTextWrapper: {
    listStyleType: 'none',
    width: '100%',
    paddingLeft: '0',
  },
  noPitchFinderDataLinksWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'space-between',
    flexWrap: 'wrap',
    listStyleType: 'none',
  },
  noPitchFinderDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    gap: '20px',
    width: ['100%', '90%'],
  },
  searchBarContainer: {
    display: 'flex',
    backgroundColor: 'tertiary',
    height: '52px',
    width: '740px',
    borderRadius: '30px',
  },
  searchBarIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'secondary',
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    minWidth: '52px',
    width: '8%',
  },
  searchBarInputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '92%',
    height: '52px',
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  termsConditionsContainer: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: ['auto', '1000px'],
    backgroundColor: '#1B254A',
    m: ['20px 5px 30px', '30px 0 50px'],
    px: ['5px', '0'],
  },
  verticalIconsL: {
    color: 'tertiary',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'primary',
    height: 'inherit',
    justifyContent: 'space-evenly',
    mt: '0%',
    mr: '20px',
  },
  verticalIconsR: {
    color: 'tertiary',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'primary',
    height: 'inherit',
    justifyContent: 'space-evenly',
    mt: '-0%',
    ml: '20px',
  },

  heroContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1254px',
    margin: '0 auto',
  },
  heroMobileContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    margin: '0 auto',
    mb: '1.5rem',
  },

  heroTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '805px',
    width: '100%',
  },

  heroTextMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  heroImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    height: '100%',
  },
  heroImageMobileContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px',
    height: '100%',
  },
}

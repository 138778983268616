export default {
  primary: '#1B254A',
  secondaryBlue: '#10124C',
  primary_transparent: 'rgba(27, 37, 74, 0.95)',
  secondary: '#F3B129',
  tertiary: '#fff',
  button: 'red',
  lightBlue: '#1E2A50',
  secondaryYellow: '#FDCD00',
  grey: '#646464',
}

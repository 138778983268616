export default {
  defaultPrize: {
    fontFamily: 'quirky',
    gridArea: ['inherit', '10/1/10/2'],
    alignSelf: ['center', 'end'],
    justifySelf: ['center', 'auto'],
    color: 'secondary',
    backgroundColor: 'primary',
    width: ['100%', 'max-content'],
    borderRadius: '50px',
    p: ['15px 20px', '15px 40px'],
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'primary',
        backgroundColor: 'transparent',
        p: ['15px 20px', '15px 40px'],
      },
    },
  },
  manPrize: {
    fontFamily: 'quirky',
    gridArea: ['inherit', '7/1/9/2'],
    alignSelf: ['center', 'start'],
    justifySelf: ['center', 'auto'],
    color: 'secondary',
    backgroundColor: 'primary',
    width: ['100%', 'max-content'],
    borderRadius: '50px',
    p: ['15px 20px', '15px 40px'],
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'primary',
        backgroundColor: 'transparent',
        p: ['15px 20px', '15px 40px'],
      },
    },
  },
  orangeBgButton: {
    fontFamily: 'quirky',
    position: 'relative',
    color: 'secondary',
    backgroundColor: 'primary',
    mt: 'auto',
    width: 'max-content',
    borderRadius: '50px',
    p: ['10px 15px', '15px 40px'],
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'primary',
        backgroundColor: 'secondary',
        border: '3px solid #1B254A',
        p: ['10px 15px', '15px 40px'],
      },
    },
  },
  homeHeaderManButton: {
    fontFamily: 'quirky',
    color: 'secondary',
    backgroundColor: 'primary',
    width: ['100%', 'max-content'],
    borderRadius: '50px',
    p: ['10px', '15px 40px'],
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    mt: '20px',
    mx: ['0', '10px'],
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: '#fff',
        backgroundColor: 'primary',
        p: ['10px', '15px 40px'],
      },
    },
  },
  homeHeaderPagesButton: {
    position: ['relative', 'static'],
    top: '70px',
    fontFamily: 'quirky',
    color: 'secondary',
    backgroundColor: 'primary',
    width: ['100%', 'max-content'],
    borderRadius: '50px',
    p: '15px 40px',
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    mt: '20px',
    zIndex: ['1', 'auto'],
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'primary',
        backgroundColor: 'transparent',
        p: ['15px 20px', '15px 40px'],
      },
    },
  },
  homeHeaderPrizeManButton: {
    position: ['relative', 'static'],
    fontFamily: 'quirky',
    color: 'secondary',
    backgroundColor: 'primary',
    width: ['100%', 'max-content'],
    borderRadius: '50px',
    p: '15px 40px',
    transition: '0.2s',
    border: '3px solid #1B254A',
    cursor: 'pointer',
    mt: '20px',
    zIndex: ['1', 'auto'],
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'primary',
        backgroundColor: 'transparent',
        p: '15px 40px',
      },
    },
  },
  aboutButton: {
    fontFamily: 'bold',
    color: 'tertiary',
    backgroundColor: 'secondary',
    minWidth: '244px',
    width: 'max-content',
    borderRadius: '50px',
    height: '40px',
    fontSize: '19px',
    transition: '0.2s',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'secondary',
        backgroundColor: 'tertiary',
      },
    },
  },
  aboutButtonMobile: {
    fontFamily: 'bold',
    color: 'tertiary',
    backgroundColor: 'secondary',
    minWidth: '244px',
    width: 'max-content',
    borderRadius: '50px',
    height: '40px',
    transition: '0.2s',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'secondary',
        backgroundColor: 'tertiary',
      },
    },
  },
  videoCardWatchButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'light',
    fontWeight: 'bold',
    fontSize: ['16px', '18px'],
    color: 'tertiary',
    backgroundColor: 'secondary',
    height: ['30px', '38px'],
    width: 'max-content',
    borderRadius: '50px',
    minWidth: ['230px', '255px'],
    transition: '0.2s',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        color: 'secondary',
        backgroundColor: 'tertiary',
      },
    },
  },

  pitchesListItemButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'quirky',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'primary',
    backgroundColor: 'secondary',
    height: '30px',
    width: 'max-content',
    borderRadius: '50px',
    minWidth: '133px',
    textTransform: 'uppercase',
    transition: '0.2s',
    cursor: 'pointer',
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        backgroundColor: 'primary',
        color: 'secondary',
      },
    },
  },
}

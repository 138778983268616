import React from 'react'
import { ModalContextProvider } from './modal'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const AppProviders = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalContextProvider>{children}</ModalContextProvider>
    </QueryClientProvider>
  )
}

export { AppProviders }

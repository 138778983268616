export default {
  homeManTitle: {
    maxWidth: ['auto', '900px'],
    mb: ['0', '-30px'],
  },
  homeDefaultTitle: {
    maxWidth: ['auto', '800px'],
  },
  partnersGroup: {
    my: '10px',
    px: ['20px', '200px'],
  },
  partnersLogo: {
    maxHeight: '50px',
    alignSelf: 'center',
    justifySelf: 'center',
    ':hover': {
      transform: 'scale(1.2)',
    },
    transition: '200ms',
  },
  hundredLogo: {
    maxWidth: ['150px', '180px'],
    ':hover': {
      transform: 'scale(1.2)',
    },
    transition: '200ms',
  },
  footer: {
    px: ['20px', '200px'],
  },
  navbarLogo: {
    width: ['150px', '200px'],
    cursor: 'pointer',
  },
  logoImage: {
    my: '10px',
    height: ['60px', 'auto'],
    px: ['20px', '200px'],
  },
  batImage: {
    maxHeight: '150px',
    px: ['20px', '0'],
    my: ['20px', '0'],
  },
  imageSketch: {
    mt: '20px',
    px: ['20px', '200px'],
  },
  pagesSketch: {
    position: ['relative', 'static'],
    bottom: '100px',
    mt: ['10px', '0'],
    mb: ['-130px', '0'],
    maxHeight: ['200px', '560px'],
    zIndex: ['0', 'auto'],
    container: {
      alignSelf: ['auto', 'end'],
    },
  },
  prizeManSketch: {
    position: ['relative', 'static'],
    mb: ['-50px', '0'],
    maxHeight: ['190px', '560px'],
    zIndex: ['0', 'auto'],
    container: {
      alignSelf: ['auto', 'end'],
    },
  },
  homeLinkImage: {
    width: '100%',
  },
  homeLinkVideo: {
    height: '100%',
    width: '100%',
  },
  playBtn: {
    height: ['50px', '80px'],
    position: 'relative',
    mb: ['-50px', '-80px'],
    bottom: ['70%', '60%'],
    left: ['46%', '46%'],
    cursor: 'pointer',
  },
  chevron: {
    height: '30px',
    display: 'block',
    filter:
      'invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg) brightness(105%) contrast(105%)',
  },
  discoverImage: {
    height: ['auto', '100%'],
    position: ['relative', 'static'],
    top: '5px',
  },
  socialLogo: {
    m: ['20px 0 20px', '40px 0 0'],
    height: '60px',
    width: '60px',
  },
  mainVideo: {
    thumbnail: {
      height: ['auto', '100%'],
      objectFit: 'contain',
      mb: ['-5px', '0'],
      container: {
        height: ['auto', '377px'],
        backgroundColor: '#000',
      },
    },
    logo: {
      height: ['auto', '100%'],
      container: {
        height: ['auto', '105px'],
      },
    },
  },
  modalVideoLogo: {
    height: '100%',
    objectFit: 'cover',
    container: {
      height: ['90px', '130px'],
      width: '100%',
      backgroundColor: 'primary',
      display: 'flex',
      justifyContent: 'center',
      pt: ['10px', '0'],
    },
  },
  videoList: {
    images: {
      objectFit: 'contain',
      height: '100%',
      container: {
        height: '152px',
        backgroundColor: '#000',
      },
    },
    logos: {
      objectFit: 'cover',
      height: '100%',
      container: {
        height: '152px',
      },
    },
  },
  navbarBars: {
    cursor: 'pointer',
    height: '30px',
    filter:
      'invert(83%) sepia(65%) saturate(3927%) hue-rotate(338deg) brightness(99%) contrast(92%)',
  },
  navbarCross: {
    cursor: 'pointer',
    height: '30px',
  },
  serpentineIcon: {
    width: ['80px', '60px'],
    transition: '200ms',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  batIcon: {
    width: ['70px', '100px'],
    transition: '200ms',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  ballIcon: {
    width: ['40px', '57px'],
    transition: '200ms',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  dropsIcon: {
    width: '70px',
    transition: '200ms',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  mapIcon: {
    height: '560px',
    width: 'auto',
  },
  videoCarouselItemImage: {
    height: '100%',
    maxHeight: '265px',
    objectFit: 'cover',
    marginBottom: '1rem',
    cursor: 'pointer',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  ballTermIcon: {
    mt: '70vh',
    width: '57px',
    height: '57px',
  },
  bailTermIcon: {
    mt: '90vh',
    width: '88px',
    height: '67px',
  },
  splashTermIcon: {
    mb: '80vh',
    width: '85px',
    height: '118px',
  },
  batTermIcon: {
    mb: '70vh',
    width: '122px',
    height: '163px',
  },
  searchIcon: {
    height: '22px',
    width: '22px',
    container: {
      height: '22px',
      width: '22px',
    },
  },
  heroImage: {
    width: '100%',
    height: '100%',
  },
}

export default redirect
import Router from 'next/router'

function redirect(params) {
  const { ctx = {}, location, status = 302 } = params || {}

  if (ctx.res) {
    ctx.res.writeHead(status, {
      Location: location,
      'Content-Type': 'text/html; charset=utf-8',
    })
    ctx.res.end()
    return
  }

  Router.replace(location)
}

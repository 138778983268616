export default {
  openArrow: {
    mb: '1vh',
    mr: '1.5vw',
    postion: 'absolute',
    zIndex: 2,
  },
  closeArrow: {
    mr: '1.5vw',
    transform: 'rotate(90deg)',
    postion: 'absolute',
    zIndex: 2,
  },
  info: {
    width: '3vh',
    height: ['1vh', '0.5vh'],
    ml: '0.5vw',
    mt: '1vh',
  },
  infoToggle: {
    width: '3vh',
    height: '0.5vh',
    ml: '0.5vw',
  },
  nextArrow: {
    fontWeight: 100,
    p: 0,
    mt: '-0.5vh',
    width: ['40px', '50px', '100px'],
    height: ['40px', '50px', '100px'],
  },
  download: { color: 'accessories', fontSize: '24px', mr: '8px' },
}
